import { useState, useEffect } from "react";

import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  where,
} from "firebase/firestore";

const useClients = ({ organizationId, includeDisabled = true }) => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    const db = getFirestore();
    let clientsCollection = collection(
      db,
      "organizations",
      organizationId,
      "clients",
    );

    if (!includeDisabled) {
      clientsCollection = query(
        clientsCollection,
        where("status", "==", "active"),
      );
    }

    onSnapshot(clientsCollection, (snapshot) => {
      const clients = snapshot.docs.map((doc) => doc.data());
      console.log(clients);
      setClients(clients);
      setLoading(false);
    });
  }, [organizationId, includeDisabled]);

  return { clients, loading };
};

export default useClients;
