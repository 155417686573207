import React from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import exerciseDBKeysSelector from "../../../atoms/exerciseDBkeysSelector";
import colors from "../../../themes/Colors";

import PrintableFieldValue from "../../../components/molecules/PrintableFieldValue";
import ExerciseNameTableCellPrintable from "../../../components/molecules/ExerciseNameTableCellPrintable";
import useSplitExercises from "../../../hooks/useSplitExercises";

const SplitExerciseTablePrintable = ({ split }) => {
  const exerciseKeys = useRecoilValue(exerciseDBKeysSelector);
  const { t } = useTranslation();

  const { exercises } = useSplitExercises({
    protocolId: split.data().protocolId,
    splitId: split.data().id,
  });

  if (split.data().isRestSplit) {
    return null;
  }

  return (
    <Box style={{ margin: isMobile ? 0 : 12 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Exercise/Super Set</TableCell>

              <TableCell>Reps</TableCell>
              <TableCell>{t("exercise_columns.tempo")}</TableCell>
              <TableCell>Rest</TableCell>
              <TableCell>Sets</TableCell>
              <TableCell>To Failure</TableCell>
              <TableCell align="right" style={{ width: 35 }}>
                Done?
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exercises &&
              exercises.map((e, index) => {
                const value = e.data();

                if (
                  value.type === "normal_set" &&
                  !(value.exercise in exerciseKeys)
                ) {
                  return null;
                }

                return (
                  <TableRow key={value.id}>
                    <TableCell style={{ backgroundColor: colors.lightGray }}>
                      {index + 1}
                    </TableCell>
                    <ExerciseNameTableCellPrintable
                      exercise={value}
                      exerciseKeys={exerciseKeys}
                    />

                    <TableCell align="center">
                      <PrintableFieldValue
                        type={value.type}
                        field="reps"
                        value={value.reps}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PrintableFieldValue
                        type={value.type}
                        field="tempo"
                        value={value.tempo}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PrintableFieldValue
                        type={value.type}
                        field="rest"
                        value={value.rest}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PrintableFieldValue
                        type={value.type}
                        field="sets"
                        value={value.sets}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <PrintableFieldValue
                        type={value.type}
                        field="setsToFailure"
                        value={value.setsToFailure}
                      />
                    </TableCell>
                    <TableCell align="right" style={{ width: 35 }}>
                      <Checkbox />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SplitExerciseTablePrintable;
