import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import AvatarName from "../../../../components/molecules/AvatarName";
import { getGridHeight } from "../../../../data/Library";
import useEvents from "../../../../hooks/useEvents";

const CoachTable = ({ coaches, onCoachClicked, onAddCoachClicked }) => {
  const theme = useTheme();
  const events = useEvents();

  const storedModel = localStorage.getItem("coachDataGridColumns");
  const [visibleModel, setVisibilityModel] = React.useState(
    storedModel ? JSON.parse(storedModel) : {},
  );

  const columns = React.useMemo(() => {
    return [
      // { field: "id", headerName: "ID", width: 150 },
      {
        field: "avatar",
        headerName: "",
        headerAlign: "center",
        align: "center",
        width: 60,
        hideable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          return (
            <AvatarName
              showName={false}
              firstName={params.row.firstName}
              lastName={params.row.lastName}
              url={params.row.avatarUrl}
            />
          );
        },
      },
      {
        field: "firstName",
        headerName: "First Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "lastName",
        headerName: "Last Name",
        minWidth: 100,
        flex: 1,
        hideable: false,
        headerAlign: "left",
        align: "left",
      },
      {
        field: "status",
        headerName: "Active",
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        type: "singleSelect",
        valueOptions: ["active", "disabled"],
        renderCell: (params) => {
          if (params.value === "disabled") {
            return (
              <Box style={{ color: theme.palette.primary.main }}>
                {params.value}
              </Box>
            );
          }

          return <Box>{params.value}</Box>;
        },
      },
      {
        field: "role",
        headerName: "Role",
        headerAlign: "center",
        align: "center",
        minWidth: 100,
        type: "singleSelect",
        valueOptions: ["coach", "admin"],
      },
    ];
  }, []);

  const rows = React.useMemo(() => {
    return coaches.map((coach) => {
      return {
        id: coach.uid,
        ...coach,
      };
    });
  }, [coaches]);

  return (
    <Box style={{ margin: 12 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Coaches</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (onAddCoachClicked) {
              onAddCoachClicked();
              events.logButtonClick({
                buttonName: "Add New Coach",
                pageName: "Coaches",
              });
            }
          }}
        >
          Add New Coach
        </Button>
      </Box>
      {/* CHANGE HEIGHT HERE */}
      <Box sx={{ marginTop: 1, height: getGridHeight() }}>
        <DataGrid
          style={{ width: "100%" }}
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-filterIcon": {
              color: theme.palette.primary.main,
            },
            ".MuiDataGrid-menuIconButton": {
              color: theme.palette.primary.main,
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: "8px",
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: "15px",
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: "22px",
            },
          }}
          columnVisibilityModel={visibleModel}
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          getRowHeight={() => {
            return "auto";
          }}
          onColumnVisibilityModelChange={(model) => {
            localStorage.setItem("coachDataGridColumns", JSON.stringify(model));
            setVisibilityModel(model);
          }}
          onRowClick={(params) => {
            if (onCoachClicked) {
              onCoachClicked(params.row);
            }
          }}
          rowVirtualization={true}
          columnVirtualization={true}
        />
      </Box>
    </Box>
  );
};

export default CoachTable;
