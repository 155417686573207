import React from "react";
// import useIdle from "../../hooks/useIdle";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/analytics";
import "firebase/compat/remote-config";

import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  Menu,
  MenuItem,
  LinearProgress,
  Grid,
  Link,
  Container,
} from "@mui/material";
import Bugsnag from "@bugsnag/js";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AssessmentIcon from "@mui/icons-material/Assessment";

import logoImage from "../../assets/Logo.png";

import MenuItemComponent from "../../components/MenuItem";
import ReportPage from "./children/ReportPage";
import ClientsPage from "./children/ClientsPage";
import CoachesPage from "./children/CoachesPage";
import MessagesPage from "./children/MessagesPage";
import SharedVideoPage from "./children/SharedVideoPage";
import ExercisesPage from "./children/ExercisesPage";
import RecipesPage from "./children/RecipePage";
import LibraryPage from "./children/LibraryPage";
import AdminPage from "./children/AdminPage";
import ProtocolsPage from "./children/ProtocolsPage";

import SubscribeNowDialog from "../../components/organisms/SubscribeNowDialog";

import UserData, { RoleString, uploadUserAvatar } from "../../data/UserData";
import GlobalData, { pages } from "../../data/GlobalData";
import MessageData from "../../data/MessageData";
import OrganizationData from "../../data/OrganizationData";
import CircleImage from "../../components/atoms/CircleImage";
import useUsers from "../../hooks/useUsers";

import Emitter from "../../Emitter";

import StillTherePage from "../../components/organisms/StillTherePage";

import userDataState from "../../atoms/userDataAtom";
import organizationDataState from "../../atoms/organizationDataAtom";
import organizationIdState from "../../atoms/organizationIdAtom";
import exerciseDBState from "../../atoms/exerciseDBAtom";
import difficultyKeysState from "../../atoms/difficultyKeysAtom";
import trialDaysSelector from "../../atoms/trialDaysSelector";
import { useNavigate } from "react-router-dom";
import useRenderCount from "../../hooks/useRenderCount";
import useEvents from "../../hooks/useEvents";

function Content({ organizationData, menu }) {
  if (!organizationData) {
    return null;
  }
  switch (menu) {
    case pages.reports:
      return <ReportPage />;
    case pages.clients:
      return <ClientsPage />;
    case pages.coaches:
      return <CoachesPage />;
    case pages.protocols:
      return <ProtocolsPage />;
    case pages.messages:
      return <MessagesPage />;
    case pages.shared_videos:
      return <SharedVideoPage />;
    case pages.exercises:
      return <ExercisesPage />;
    case pages.recipes:
      return <RecipesPage />;
    case pages.library:
      return <LibraryPage />;
    case pages.admin:
      return <AdminPage />;
    default:
      return <Typography>Default</Typography>;
  }
}

const SubscribeLink = ({ organizationData }) => {
  if (!organizationData) {
    return null;
  }
  if (
    organizationData &&
    organizationData.acccount &&
    !organizationData.account.subscription
  ) {
    return (
      <Link href="/subscribe">
        <Typography style={{ color: "black" }}>Subscribe</Typography>
      </Link>
    );
  }
  return null;
};

const HomePage = () => {
  useUsers();

  useRenderCount({ name: "HomePage" });

  const navigate = useNavigate();
  const savedPage = localStorage.getItem("lastPage");
  const accountData = useRecoilValue(trialDaysSelector);

  const setOrganizationIdState = useSetRecoilState(organizationIdState);

  const setExerciseDBState = useSetRecoilState(exerciseDBState);
  const setDifficultyKeysState = useSetRecoilState(difficultyKeysState);
  const [userData, setUserData] = useRecoilState(userDataState);
  const [organizationData, setOrganizationData] = useRecoilState(
    organizationDataState,
  );

  const [menu, setMenu] = React.useState(savedPage ? savedPage : pages.reports);

  const menuStateStore = localStorage.getItem("menuState");
  const [menuState, setMenuState] = React.useState(
    menuStateStore ? menuStateStore : "expanded",
  );

  const [update, setUpdate] = React.useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [updatingAvatar, setUpdatingAvatar] = React.useState(false);
  const [avatarUploadProgress, setAvatarUploadProgress] = React.useState(0);

  const events = useEvents();

  const sessionTimerManualLogout = () => {
    handleSignOut("Manual From Session Timeout");
  };

  React.useEffect(() => {
    async function getData() {
      try {
        const uid = localStorage.getItem("uid");
        const userData = await UserData.getUserData(uid);

        if (
          !userData ||
          !firebase.auth().currentUser ||
          firebase.auth().currentUser.uid !== userData.authId
        ) {
          localStorage.removeItem("uid");
          localStorage.removeItem("email");

          await firebase.auth().signOut();
          navigate("/");
          return;
        }

        const organizationData = await OrganizationData.getOrganization(uid);
        setUserData(userData);
        setOrganizationData(organizationData);

        localStorage.setItem("organizationId", organizationData.id);
        setOrganizationIdState(organizationData.id);

        const exercises = await GlobalData.getExercises({
          organizationId: organizationData.id,
        });
        setExerciseDBState(exercises);

        const difficultyKeys = await GlobalData.getDifficultyKey();
        setDifficultyKeysState(difficultyKeys);

        if (userData.role !== "admin" && savedPage === pages.admin) {
          localStorage.setItem("lastPage", pages.reports);
          setMenu(pages.reports);
        }
      } catch (error) {
        Bugsnag.notify(error);

        localStorage.removeItem("uid");
        localStorage.removeItem("organizationId");
        localStorage.removeItem("email");
        await firebase.auth().signOut();
        navigate("/signin");
      }
    }
    getData();
  }, [update]);

  React.useEffect(() => {
    if (userData && userData.role !== "admin" && savedPage === pages.admin) {
      localStorage.setItem("lastPage", pages.reports);
      setMenu(pages.reports);
    }
  }, [savedPage, userData]);

  React.useEffect(() => {
    let customSubscription;

    GlobalData.getCustomExercisesSubscription(
      async () => {
        const exercises = await GlobalData.getExercises();
        setExerciseDBState(exercises);
      },
      (subscription) => {
        customSubscription = subscription;
      },
    );
    return () => {
      if (customSubscription) {
        customSubscription();
      }
    };
  }, [organizationData, setExerciseDBState]);

  React.useEffect(() => {
    const organizationId = localStorage.getItem("organizationId");
    const uid = localStorage.getItem("uid");

    let subscription = null;
    if (organizationId) {
      // subscribe to messageHistory
      subscription = MessageData.getMessagesHistorySubscription({
        uid,
        onMessages: async (messages) => {
          if (messages && messages.length > 0) {
            if (!("Notification" in window)) {
              return;
            }

            const lastMessage = messages[0].data();

            const user = `Mighty45 - ${lastMessage.message.fromUser.firstName} ${lastMessage.message.fromUser.lastName}`;

            if (Notification.permission === "granted") {
              const notification = new Notification(user, {
                body: lastMessage.message.message,
                icon: lastMessage.message.fromUser.avatarUrl,
              });

              notification.onclick = (event) => {
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.focus();
                notification.close();
              };
            }

            await MessageData.markMessageHistorySent({
              messageId: lastMessage.id,
              uid: uid,
            });
          }
        },
      });
    }

    return () => {
      if (subscription) {
        subscription();
      }
    };
  }, []);

  Emitter.removeListener("orgChanged");
  Emitter.on("orgChanged", () => {
    setUpdate(new Date());
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async (methodType) => {
    setAnchorEl(null);
    localStorage.removeItem("uid");
    localStorage.removeItem("email");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("credentials.user.uid");
    const signoutEvent = { method: methodType };
    events.logSignout(signoutEvent);

    await firebase.auth().signOut();

    navigate("/signin");
  };

  const handleChangeAvatar = async (e) => {
    setAnchorEl(null);
    if (e.target.files[0]) {
      const image = e.target.files[0];
      setUpdatingAvatar(true);
      setAvatarUploadProgress(0);
      await uploadUserAvatar({
        uid: userData.uid,
        file: image,
        progressFunction: (progress) => {
          setAvatarUploadProgress(progress);
        },
      });
      setUpdatingAvatar(false);
      setAvatarUploadProgress(0);
      setUpdate(new Date());
    }
  };

  function handleMenuEvent(title, id) {
    const menuEvent = { objectName: title, objectId: id };
    events.logMenuComponent(menuEvent);
  }

  function handleHyperlink(title, link) {
    const linkEvent = { objectName: title, hyperlink: link };
    events.logHyperlink(linkEvent);
  }

  // REFACTOR: this is a mess. Needs to change to component out side of this component.
  function showProgress() {
    if (updatingAvatar) {
      return (
        <LinearProgress
          style={{ marginTop: 2 }}
          variant="determinate"
          value={avatarUploadProgress}
        />
      );
    }
  }

  // REFACTOR: this is a mess. Needs to change to component out side of this component.
  function ArrowIcon() {
    if (menuState === "expanded") {
      return (
        <ArrowLeftIcon
          color="black"
          sx={{ fontSize: 32, marginBottom: 0 }}
          onClick={() => {
            if (menuState === "expanded") {
              setMenuState("collapsed");
              localStorage.setItem("menuState", "collapsed");
            } else {
              setMenuState("expanded");
              localStorage.setItem("menuState", "expanded");
            }
          }}
        />
      );
    }
    return (
      <ArrowRightIcon
        color="black"
        sx={{ fontSize: 32, marginBottom: 0 }}
        onClick={() => {
          if (menuState === "expanded") {
            setMenuState("collapsed");
            localStorage.setItem("menuState", "collapsed");
          } else {
            setMenuState("expanded");
            localStorage.setItem("menuState", "expanded");
          }
        }}
      />
    );
  }

  return (
    <>
      <AppBar
        elevation={0}
        position="sticky"
        style={{ backgroundColor: "white" }}
      >
        {accountData.expired && <SubscribeNowDialog />}

        <StillTherePage
          onManualLogout={sessionTimerManualLogout}
          onForcedLogout={() => {
            handleSignOut("Session Timed Out");
          }}
        />

        <Box boxShadow={1}>
          <Toolbar>
            <Box
              style={{
                height: 120,
                width: "100%",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box style={{ textAlign: "left", justifyContent: "flex-start" }}>
                <img
                  alt="Mighty45 Logo"
                  style={{}}
                  width="146px"
                  height="78px"
                  src={logoImage}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </Box>
              <Box
                style={{
                  textAlign: "right",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Box style={{ justifyContent: "flex-end", marginRight: 10 }}>
                  <Typography style={{ color: "black", fontWeight: "normal" }}>
                    {userData
                      ? `${userData.firstName} ${userData.lastName}`
                      : ""}
                  </Typography>
                  <Typography style={{ color: "black", fontWeight: "bold" }}>
                    {userData && userData.role
                      ? `${RoleString[userData.role]}`
                      : ""}
                  </Typography>
                  <Typography style={{ color: "black" }}>
                    {organizationData ? `${organizationData.name}` : ""}
                  </Typography>
                  <SubscribeLink organizationData={organizationData} />
                </Box>
                <Box
                  style={{
                    justifyContent: "flex-end",
                    marginRight: 8,
                    marginTop: 8,
                  }}
                >
                  <Box style={{ height: 75, width: "auto" }}>
                    <CircleImage
                      firstName={userData ? userData.firstName : ""}
                      lastName={userData ? userData.lastName : ""}
                      size={64}
                      url={
                        userData && userData.avatarUrl
                          ? userData.avatarUrl
                          : null
                      }
                      onClick={handleClick}
                    />
                    {showProgress()}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <label>
                <MenuItem>Change avatar</MenuItem>
                <input
                  hidden={true}
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleChangeAvatar}
                />
              </label>

              <MenuItem
                onClick={() => {
                  handleSignOut("Avatar Signout Click");
                }}
              >
                Sign out
              </MenuItem>
            </Menu>
          </Toolbar>
        </Box>
      </AppBar>
      <Box sx={{ backgroundColor: "white", display: "flex", width: "100%" }}>
        <Box
          boxShadow={0}
          style={{
            width: menuState === "expanded" ? 175 : 55,
            height: window.innerHeight - 130,
          }}
        >
          <Box
            style={{
              marginTop: 5,
              marginBottom: 0,
              textAlign: "right",
            }}
          >
            <ArrowIcon />
          </Box>

          <List>
            <ListItem>
              <MenuItemComponent
                id={pages.reports}
                title="Reports"
                menuState={menuState}
                iconComponent={AssessmentIcon}
                enabled={menu === pages.reports ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Reports", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>
            <ListItem>
              <MenuItemComponent
                id={pages.clients}
                title="Clients"
                menuState={menuState}
                enabled={menu === pages.clients ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Clients", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>
            <ListItem>
              <MenuItemComponent
                id={pages.coaches}
                title="Coaches"
                menuState={menuState}
                enabled={menu === pages.coaches ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Coaches", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>
            <ListItem>
              <MenuItemComponent
                id={pages.messages}
                title="Messages"
                menuState={menuState}
                enabled={menu === pages.messages ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Messages", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>
            <ListItem>
              <MenuItemComponent
                id={pages.shared_videos}
                title="Shared Videos"
                menuState={menuState}
                enabled={menu === pages.shared_videos ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Shared Videos", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>
            <ListItem>
              <MenuItemComponent
                id={pages.protocols}
                title="Training Plans"
                menuState={menuState}
                enabled={menu === pages.protocols ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Training Plans", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>

            <ListItem>
              <MenuItemComponent
                id={pages.exercises}
                title="Exercises"
                menuState={menuState}
                enabled={menu === pages.exercises ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Exercises", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>

            <ListItem>
              <MenuItemComponent
                id={pages.recipes}
                title="Nutrition"
                menuState={menuState}
                enabled={menu === pages.recipes ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Nutrition", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>

            <ListItem>
              <MenuItemComponent
                id={pages.library}
                title="Library"
                menuState={menuState}
                enabled={menu === pages.library ? true : false}
                menuClicked={(id) => {
                  handleMenuEvent("Library", id);
                  localStorage.setItem("lastPage", id);
                  setMenu(id);
                }}
              />
            </ListItem>

            {userData && userData.role === "admin" && (
              <ListItem>
                <MenuItemComponent
                  id={pages.admin}
                  title="Admin"
                  menuState={menuState}
                  enabled={menu === pages.admin ? true : false}
                  menuClicked={(id) => {
                    handleMenuEvent("Admin", id);
                    localStorage.setItem("lastPage", id);
                    setMenu(id);
                  }}
                />
              </ListItem>
            )}
          </List>
        </Box>

        <Box
          style={{
            backgroundColor: "#F6F7FB",
            width: `calc(100% - ${menuState === "expanded" ? 175 : 55}px)`,
          }}
        >
          <Container maxWidth={false} sx={{ height: "100%" }}>
            <Content menu={menu} organizationData={organizationData} />
          </Container>

          <Box style={{ padding: 24, marginBottom: 0, marginTop: 12 }}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item>
                <Typography>
                  © 2020 Mighty45 LLC | All rights reserved
                </Typography>
              </Grid>

              <Grid item>
                <Link
                  href="https://mighty45.tawk.help/"
                  target="_blank"
                  onClick={() => {
                    handleHyperlink("Help", "https://mighty45.tawk.help/");
                  }}
                >
                  <Typography>Help</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://sites.google.com/tahelpya.com/mighty45-api/home"
                  target="_blank"
                  onClick={() => {
                    handleHyperlink(
                      "API",
                      "https://sites.google.com/tahelpya.com/mighty45-api/home",
                    );
                  }}
                >
                  <Typography>API</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://mighty45.com/about"
                  target="_blank"
                  onClick={() => {
                    handleHyperlink("About", "https://mighty45.com/about");
                  }}
                >
                  <Typography>About</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  onClick={() => {
                    handleSignOut("Footer Signout Click");
                  }}
                >
                  <Typography>Sign out</Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
